.login {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}
.login .part {
  width: 50%;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .part h3 {
  color: #121e1e;
  font-size: 3rem;
}
.login .loginregion {
  background-color: #f2b42b;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  /* overflow: hidden; */
}
.login .loginregion h4 {
  letter-spacing: 1px;
  color: #121e1e;
}
.login .loginregion input {
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.login .loginregion button {
  width: 53%;
  margin-left: 25%;
  border: none;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: bolder;
  background-color: #121e1e;
  font-size: 1rem;
  margin-top: 30px;
  color: white;
}
.login .loginregion input {
  border: 2px solid #121e1e;
  width: 90%;
  margin-left: 5%;
}

.login .loginregion input:focus {
  outline: none;
}
.login .loginregion > div {
  width: 50%;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* overflow: hidden; */
}

.login .loginregion h6 {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #121e1e;
  margin-left: 5%;
}

#googleLoginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between icon and text */
  width: 90%; /* Adjust width as needed */
  max-width: 380px; /* Optional: Limit the max width */
  padding: 10px 16px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  background-color: white;
  color: #3c4043;
  font-size: 14px;
  font-weight: 500;
  font-family: Arial, sans-serif;
  cursor: pointer;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: 0px 1px 3px rgba(60, 64, 67, 0.15),
    0px 4px 8px rgba(60, 64, 67, 0.15);
  margin-top: 20px;
}

#googleLoginButton:hover {
  cursor: "pointer";
  background-color: #f8f9fa;
  box-shadow: 0px 2px 4px rgba(60, 64, 67, 0.2),
    0px 6px 10px rgba(60, 64, 67, 0.2);
}

#googleLoginButton:active {
  background-color: #e8eaed;
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 4px rgba(60, 64, 67, 0.3);
}

#googleLoginButton img {
  height: 18px;
  width: 18px;
}

@media (max-width: 768px) {
  .login {
    flex-direction: column;
  }
  .login .part {
    width: 100%;
    margin-left: 0;
  }
  .login .part h3 {
    font-size: 2rem;
  }
  .login .loginregion {
    width: 100%;
  }
  .login .loginregion > div {
    margin-left: 20%;
    width: 60%;
  }
  .login .loginregion button {
    width: 59%;
    margin-left: 21%;
  }
}
