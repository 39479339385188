.homepage .upper {
  background-color: #ffffff;
  width: 100vw;
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  /* border-bottom: 3px solid #f2b42b; */
  padding-left: 0px;
  z-index: 1008;
}
.innerview {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  border: 4px solid #f2b42b;
}
.homepage .upper .part {
  display: flex;
  flex-direction: row;
}
.homepage .upper .userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homepage .upper .part img {
  margin-right: 10px;
}
.homepage .upper h4 {
  font-size: 1rem;
  letter-spacing: 1px;
  color: #121e1e;
}
.homepage .upper button {
  margin: 0;
  margin-right: 10px;
  background-color: #f2b42b;
  border: none;
  padding: 10px;
  /* border-radius: 5px; */
  font-weight: bolder;
  color: white;
}
.homepage .upper h4 {
  margin: 5px;
  margin-right: 20px;
  font-size: 1rem;
}
.homepage .upper h5 {
  margin: 5px;
  margin-right: 20px;
  color: black;
  font-size: 0.8rem;
}
.homepage .middle {
  padding: 0 50px;
  margin-top: 80px;
  padding-top: 40px;
  z-index: 1010;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.homepage .middle input {
  padding: 10px;
  border: none;
  border-bottom: 2px solid #2b4a4e;
  /* border-radius: 5px; */
  min-width: 200px;
}
.homepage .middle input:focus {
  outline: none;
  border-bottom: 2px solid #f2b42b;
}
.homepage .middle button {
  background-color: white;
  border: 2px solid #f2b42b;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;
  font-weight: bolder;
}
.homepage .middle .highlight {
  background-color: #f2b42b;
  color: white;
}

.homepage .lower {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  z-index: 1010;
  background-color: white;
}
.homepage .lower .eachmedia {
  margin: 20px;
  width: 20%;
  margin-bottom: 4rem;
  margin-right: 0em;
  padding-left: 0%;
  padding-bottom: 20px;
}
.homepage .lower .eachmedia > div {
  border-radius: 5px;
}
.homepage .lower .eachmedia .wrapper {
  overflow: hidden;
  border-radius: 5px;
}
.homepage .lower .eachmedia img,
.homepage .lower .eachmedia video {
  width: 100%;
  height: 220px;
  background-color: black;
  border-radius: 5px;
}
.homepage .lower .eachmedia img {
  height: auto;
}
.homepage .lower h4 {
  color: black;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  background-color: rgba(242, 180, 43, 0.1);
  padding: 10px;
  border-radius: 5px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage .lower .eachmedia button {
  background-color: #121e1e;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bolder;
  /* border-bottom: 3px solid #f2b42b; */
  margin: 5px;
}

.positionmodal {
  position: fixed;
  top: 10vh;
  left: 10vw;
  right: 10vw;
  bottom: 10vh;
  background-color: #2b4a4e;
  border: 2px solid #f2b42b;
  border-radius: 5px;
  z-index: 1039;
}
.positionmodal button {
  background-color: #f2b42b;
  padding: 8px;
  border-radius: 5px;
  border: none;
  color: black;
  font-weight: bolder;
  margin: 10px;
}
.positionmodal .special {
  background-color: white;
  color: black;
}
.positionmodal #close {
  float: left;
}
.positionmodal #download {
  padding: 12px;
  font-size: 1.3rem;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .homepage .lower .eachmedia {
    width: 70%;
    align-items: center;
    margin: 0;
  }
  .homepage .upper {
    flex-direction: column;
    height: auto;
    z-index: 1020;
  }
  .homepage .upper button {
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
  }
  .homepage .lower h4 {
    margin: 5;
  }
  .ReactModal__Overlay,
  .ReactModal__Overlay--after-open {
    z-index: 1035 !important;
  }
  .homepage .middle {
    margin-top: 150px;
  }
  .homepage .middle button {
    margin-top: 20px;
  }
  .homepage .middle {
    flex-direction: column;
  }
  .homepage .middle input {
    margin-top: 30px;
  }
  .homepage .upper .actions {
    display: flex;
    flex-direction: column;
  }
  .homepage .upper .userinfo {
    flex-direction: column-reverse;
  }
  .homepage .upper .part img {
    margin-left: -10px;
  }
}
